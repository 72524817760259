<template>
  <div class="chargeoofline">
    <NavBar name="收费记录"></NavBar>
    <div class="chargeoofline-box" style="margin-top: 20px">
      <div class="top-box">
        <div class="item">
          <div>{{ statistics && statistics.key_total_price }}</div>
          <div class="name">缴费总金额:</div>
        </div>
        <div class="item">
          <div style="color: #3d7eff">
            {{ statistics && statistics.key_pay_price }}
          </div>
          <div class="name">实收总金额:</div>
        </div>
        <div class="item">
          <div style="color: #666666">
            {{ statistics && statistics.coupon_price }}
          </div>
          <div class="name">减免费用:</div>
        </div>
        <div class="item">
          <div style="color: #02ce80">
            {{ statistics && statistics.zero_price }}
          </div>
          <div class="name">抹零金额:</div>
        </div>
        <div class="item">
          <div style="color: #02ce80">
            {{ statistics && statistics.discount_money }}
          </div>
          <div class="name">优惠总金额:</div>
        </div>
      </div>
      <el-form :inline="true">
        <el-form-item>
          <MyInput placeholder="请输入缴费人" v-model="params.contact_name" right>
            <template slot="pre">缴费人:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyInput placeholder="请输入收费人" v-model="params.bill_payee" right>
            <template slot="pre">收费人:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyInput placeholder="请输入商铺号" v-model="params.stall_numbers" right>
            <template slot="pre">商铺号:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyInput placeholder="请输入凭证号" v-model="params.bill_offline_no">
            <template slot="pre">凭证号:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <el-select class="offset" v-model="params.print_count" @change="currentChange(1)" clearable
            placeholder="选择是否打印">
            <el-option :value="0" label="未打印"></el-option>
            <el-option :value="1" label="已打印"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select class="offset" v-model="params.invoicing" @change="currentChange(1)" clearable placeholder="选择是否开票">
            <el-option :value="0" label="未开票"></el-option>
            <el-option :value="1" label="已开票"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker class="offset" v-model="date" @change="changeDate" format="yyyy-MM-dd" start-placeholder="开始时间"
            end-placeholder="结束时间" value-format="yyyy-MM-dd" type="daterange"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select class="offset" v-model="params.pay_type" @change="currentChange(1)" clearable placeholder="选择收款方式">
            <el-option v-for="item in $chargeWay" :value="item.id" :key="item.id" :label="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <MyButton left @click="currentChange(1)">
            搜索
            <template slot="preImage">
              <img src="../../../unit/img/search.png" alt />
            </template>
          </MyButton>
          <MyButton left :accessId="2761" @click="addCharge" right>
            新建收费
            <template slot="preImage">
              <img src="../../../unit/img/zj.png" alt />
            </template>
          </MyButton>
          <MyButton @click="imported" :accessId="63656">
            导出
            <template slot="preImage">
              <img src="../../../unit/img/down.png" alt />
            </template>
          </MyButton>
          <MyButton left @click="imported2" :accessId="63656">
            导出明细
            <template slot="preImage">
              <img src="../../../unit/img/down.png" alt />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>
      <div class="chargeoofline-table">
        <el-table :data="tableData" :header-cell-style="{ 'text-align': 'center', background: 'rgb(245, 245, 245)' }"
          :cell-style="{ 'text-align': 'center' }" v-loading="loading" height="95%">
          <el-table-column label="凭证号" prop="voucher_number"></el-table-column>
          <el-table-column label="收费时间" prop="pay_time"></el-table-column>
          <el-table-column label="收费项目" prop="bill_offline_name"></el-table-column>
          <el-table-column label="商铺号" prop="stall_numbers"></el-table-column>
          <el-table-column label="收费金额" prop="key_total_price"></el-table-column>
          <!-- <el-table-column label="收费金额" prop="key_total_price">
            <template #default="{ row }">
              {{ Number(parseFloat(row.key_pay_price + row.balance_deduction + row.coupon_price +
                row.discount_money).toFixed(2)) }} </template>
          </el-table-column> -->
          <el-table-column label="余额抵扣" prop="balance_deduction"></el-table-column>
          <el-table-column label="基础费用减免" prop="coupon_price" width="150"></el-table-column>
          <el-table-column label="抹零金额" prop="zero_price"></el-table-column>
          <el-table-column label="优惠金额" prop="discount_money"></el-table-column>
          <el-table-column label="实收金额" prop="key_pay_price"></el-table-column>
          <el-table-column label="缴费人" prop="merchants_stall_name"></el-table-column>
          <el-table-column label="收费人" prop="bill_payee">
            <template #default="{ row }">
              <span v-if="row.pay_type == 10">自主缴费</span>
              <span v-else>{{ row.bill_payee }}</span>
            </template>
          </el-table-column>
          <el-table-column label="收费方式" prop="pay_type">
            <template slot-scope="scope">{{
              getType(scope.row.pay_type)
            }}</template>
          </el-table-column>
          <el-table-column label="打印次数" prop="print_count"></el-table-column>
          <el-table-column label="是否开票" prop="invoicing_record_id">
            <template slot-scope="scope">
              <span v-if="scope.row.invoicing_record_id">已开票</span>
              <span v-else>未开票</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="is_cancellations">
            <template slot-scope="scope">
              <span v-if="scope.row.is_cancellations == 1">已作废</span>
              <span v-else>正常</span>
            </template>
          </el-table-column>
          <el-table-column label="对公转账凭证">
            <template #default="{ row }">
              <pop tips="查看" :accessId="86842" @myclick="checkdgong(row)">
                <img src="../../../unit/img/xq.png" class="icon" alt />
              </pop>
            </template>
          </el-table-column>
          <el-table-column label="缴费凭证" width="80px">
            <template slot-scope="scope">
              <div class="slot-box">
                <pop tips="查看" :accessId="86842" @myclick="examine(scope.row)">
                  <img src="../../../unit/img/xq.png" class="icon" alt />
                </pop>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="开票" width="80px">
            <template slot-scope="scope">
              <div class="slot-box">
                <pop popLeft tips="开票" :accessId="63658" @myclick="makeAdd(scope.row)"
                  v-if="!scope.row.invoicing_record_id">
                  <img class="icon" src="@/assets/img/icon/pingzheng.png" alt="" />
                </pop>
                <pop popLeft tips="查看" :accessId="63659" @myclick="examineMake(scope.row)" v-else>
                  <img class="icon" src="@/assets/img/icon/chakan.png" alt="" />
                </pop>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80px">
            <template slot-scope="scope">
              <div class="slot-box">
                <pop popLeft tips="作废" :accessId="63657" v-if="scope.row.is_cancellations != 1"
                  @myclick="cancellations(scope.row)">
                  <img class="icon" src="@/assets/img/icon/zuofei.png" alt="" />
                </pop>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="charageoff-page">
          <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total"
            :page-size="params.limit" :current-page="params.page" @current-change="currentChange"></el-pagination>
        </div>
      </div>
    </div>

    <el-dialog title="收费凭证" width="1230px" :visible.sync="vouchervasible" :close-on-click-modal="false">
      <GfG :detailDataRowObj="detailDataRowObj" :detailDataRow="detailDataRow"></GfG>
      <template slot="footer">
        <MyButton type="primary" @click="printDiv">打印</MyButton>
        <MyButton left @click="vouchervasible = false">关闭</MyButton>
      </template>
    </el-dialog>
    <el-dialog title="对公转账凭证" :visible.sync="dgvisible" :close-on-click-modal="false">
      <el-image class="dgimg" :src="item" alt="" v-for="item in gong_voucher" :key="item"
        :preview-src-list="gong_voucher">
      </el-image>
      <template #footer>
        <myButton @click="dgvisible = false">关闭</myButton>
      </template>
    </el-dialog>

    <el-dialog title="开票" width="500px" :visible.sync="makeVasible" :close-on-click-modal="false" @close="closeMake">
      <el-form :model="makeParams" :rules="makeParamsRules" ref="makeRuleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="开票金额">
          <el-input v-model.number="makeParams.amount" type="number" :disabled="isDisabled"
            placeholder="请输入开票金额"></el-input>
        </el-form-item>
        <el-form-item label="发票号">
          <el-input v-model="makeParams.invoice_number" :disabled="isDisabled" placeholder="请输入发票号"></el-input>
        </el-form-item>
        <el-form-item label="开票日期" prop="invoice_time">
          <el-date-picker v-model="makeParams.invoice_time" :disabled="isDisabled" style="width:100%"
            format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="makeParams.memo" :disabled="isDisabled" placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item label="发票图片">
          <div class="upload-imgx-box" v-if="makeParams.image && !isDisabled">
            <img class="upload-img" :src="makeParams.image" alt="" />
          </div>
          <div class="upload-imgx-box" v-if="makeParams.image && isDisabled">
            <!-- <img class="upload-img" :src="makeParams.image" alt="" /> -->
            <el-image style="width: 100px; height: 100px" :src="makeParams.image" :preview-src-list="[makeParams.image]">
            </el-image>
          </div>
          <el-upload action="" :auto-upload="false" accept="image/*" :show-file-list="false" :on-change="filechange"
            v-if="!isDisabled">
            <el-button icon="el-icon-upload2" type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <MyButton type="primary" @click="makeConfirm" v-if="!isDisabled">确认</MyButton>
        <MyButton left @click="makeVasible = false">关闭</MyButton>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import Voucher from "../../../components/voucher.vue";
import * as commjs from "../../../plugin/common";
import { getImg } from "../../../plugin/common";
import GfG from "../GFG";
export default {
  components: {
    GfG,
    Voucher,
  },
  data () {
    var validate = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入开票金额'));
      } else if (value <= 0) {
        return callback(new Error('请输入正确的开票金额'));
      } else {
        if (this.makeParams.amount !== '') {
          this.$refs['makeRuleForm'].clearValidate('amount');
        }
        return callback();
      }
    };
    return {
      radio: 1,
      addchargevisible: false,
      cashchargevasible: false,
      vouchervasible: false,
      addform: {
        merchants_id: "",
        merchants_card_id: "",
        bill_ids: [],
        rent_cost: 0,
        othef_cost: 0,
        daily_cost: 0,
        total_cost: 0,
        wipe_zero_amount: "",
        wipe_zero_cost: "",
        remark: "",
        derate_total_amount: 0,
      },
      params: {
        page: 1,
        limit: 10,
        pay_time_start: "",
        pay_time_end: "",
        pay_type: "",
        bill_payee: "",
        bill_offline_no: "",
        contact_name: "",
        stall_numbers: '',
        invoicing: '',
        print_count: ''
      },
      date: [],
      voucher: {},
      merchantsList: [],
      merchantsData: null,
      balance: 0,
      total: 0,
      tableData: [],
      statistics: null,
      bill_voucher: "",
      loading: false,
      dgvisible: false,
      gong_voucher: [],
      detailDataRow: [],
      detailDataRowObj: {
        merchants_stall_name: "",
        voucherName: "",
        electronicSeal: "",
        voucher_number: "",
        totalMoney: 0,
        preMoney: "",
        remark: "",
        userName: "",
        printTime: "",
        billTitemNo: "",
      },
      makeVasible: false,
      makeParams: {
        amount: '',
        invoice_number: '',
        invoice_time: '',
        image: '',
        memo: '',
        pay_id: ''
      },
      makeParamsRules: {
        // amount: [
        //   { validator: validate, trigger: 'blur' }
        // ],
        // invoice_number: [
        //   { required: true, message: '请输入发票号', trigger: 'blur' }
        // ],
        invoice_time: [
          { required: true, message: '请选择开票日期', trigger: 'change' }
        ],
        // image: [
        //   { required: true, message: '请上传发票图片', trigger: 'change' }
        // ]
      },
      isDisabled: false
    };
  },
  created () {
    this.getList();
    this.getStatistics();
    this.codeSet();
    let userInfo = window.localStorage.getItem("userInfo");
    if (userInfo) {
      this.detailDataRowObj.userName = JSON.parse(userInfo).worker.name || "";
    }
  },
  methods: {
    ...commjs,
    cancellations (row) {
      this.$confirm('确定要作废吗？', '提示', {
        type: 'warning'
      }).then(() => {
        this.$request.HttpGet('/bill_offline/cancellations', {
          bill_offline_id: row.bill_offline_id
        }).then(() => {
          this.$common.notifySuccess('作废成功');
          this.getList();
        });
      })
    },
    closeMake () {
      this.makeParams = {
        amount: '',
        invoice_number: '',
        invoice_time: '',
        image: '',
        memo: '',
        pay_id: ''
      };
      this.$refs['makeRuleForm'].clearValidate('amount');
      // this.$refs['makeRuleForm'].clearValidate('invoice_number');
      this.$refs['makeRuleForm'].clearValidate('invoice_time');
      // this.$refs['makeRuleForm'].clearValidate('image');
      this.isDisabled = false;
    },
    makeConfirm () {
      this.$refs['makeRuleForm'].validate((valid) => {
        if (valid) {
          this.$request.HttpPost('/bill_offline/addInvoice', this.makeParams).then((res) => {
            this.$common.notifySuccess('开票成功');
            this.getList();
            this.makeVasible = false;
          })
        }
      });
    },
    examineMake (row) {
      this.$request.HttpPost('/bill_offline/getInvoice', {
        id: row.invoicing_record_id
      }).then((res) => {
        this.makeParams = res.data;
        this.isDisabled = true;
        this.makeVasible = true;
      });
    },
    filechange (file) {
      let obj = new FormData();
      obj.append("file", file.raw);
      this.$request.HttpPost("/system/upload/upImg", obj).then((res) => {
        this.makeParams.image = process.env.VUE_APP_IMG_URL + res.data.fileInfo.hostUrl;
        this.$refs["makeRuleForm"].clearValidate("image");
      });
    },
    makeAdd (row) {
      this.isDisabled = false;
      this.makeParams.pay_id = row.bill_offline_id;
      this.makeVasible = true;
    },
    codeSet (type) {
      this.$request.HttpGet("/setting/edit", {
        key: "chargeNumberSetting",
      }).then((res) => {
        if (res.data) {
          this.detailDataRowObj.voucherName =
            res.data.values?.voucherName || "重庆海若实业有限公司";
          this.detailDataRowObj.electronicSeal =
            res.data.values?.electronicSeal;
        }
      });
    },
    getNumber (item) {
      let total = 0;
      total =
        item.coupon_price +
        item.balance_price +
        item.give_discount +
        item.zero_price;
      return total.toFixed(2) || 0.0;
    },
    printDiv () {
      this.$nextTick(() => {
        let divContents = document.getElementById("GFG").innerHTML;
        let a = window.open("", "");
        a.document.write("<html>");
        a.document.write("<body >");
        a.document.write(divContents);
        a.document.write("</body></html>");
        a.document.close();
        a.print();
        this.$request.HttpGet("/bill_offline_v2/updatePrintCount", {
          id: this.detailDataRowObj.id,
        }).then((res) => {
          this.vouchervasible = false;
          this.getList();
        });
      });
    },
    imported () {
      this.$request
        .HttpPost("/bill_offline/offlineListExport", this.params, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res]);
          let link = document.createElement("a");
          let url = URL.createObjectURL(blob);
          link.href = url;
          link.download = "线下收费.xlsx";
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        });
    },
    imported2 () {
      this.$request.HttpPost("/bill_offline/export2", this.params, {
        responseType: "blob",
      }).then((res) => {
        let blob = new Blob([res]);
        let link = document.createElement("a");
        let url = URL.createObjectURL(blob);
        link.href = url;
        link.download = "导出明细.xlsx";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
      });
    },
    download () {
      // "http://192.168.1.155:8010/" + this.bill_voucher

      let isUrl = this.bill_voucher.search(/https:\/\/|http:\/\//);
      if (isUrl == -1) {
        this.bill_voucher = process.env.VUE_APP_LOGIN + "/" + this.bill_voucher;
      }
      window.open(this.bill_voucher);
    },
    addCharge () {
      this.$router.push({
        path: "/charge/addCharge",
      });
    },
    getType (type) {
      let value = this.$chargeWay.find((item) => item.id == type);
      return value ? value.value : "默认";
    },
    examine (row) {
      let isUrl = row.bill_voucher.search(/https:\/\/|http:\/\//);
      if (isUrl == -1) {
        this.bill_voucher = process.env.VUE_APP_LOGIN + "/" + row.bill_voucher;
      } else {
        this.bill_voucher = row.bill_voucher;
      }
      this.detailDataRowObj.id = row.bill_offline_id;
      this.detailDataRowObj.merchants_stall_name = row.merchants_stall_name;
      this.detailDataRowObj.voucher_number = row.voucher_number;
      this.detailDataRowObj.remark = row.remark;
      this.detailDataRowObj.billTitemNo = row.bill_offline_no.slice(0, 6);
      this.detailDataRowObj.printTime = this.$common.formatTimeHms(new Date(), true);
      switch (row.pay_type) {
        case 10:
          this.detailDataRowObj.pay_type = "微信";
          break;
        case 30:
          this.detailDataRowObj.pay_type = "现金";
          break;
        case 31:
          this.detailDataRowObj.pay_type = "扫码";
          break;
        case 32:
          this.detailDataRowObj.pay_type = "扫码枪";
          break;
        case 33:
          this.detailDataRowObj.pay_type = "刷卡";
          break;
        case 34:
          this.detailDataRowObj.pay_type = "对公转账";
          break;
        case 38:
          this.detailDataRowObj.pay_type = "其他";
          break;
      }

      if (row.pay_version == 1) {
        this.$request.HttpPost('/bill_offline/offlineListItem', {
          bill_offline_id: row.bill_offline_id
        }).then((res) => {
          if (res.data) {
            let preMoney = 0;
            let totalMoney = 0;
            let id = '';
            res.data.forEach((item) => {
              if (id != item.contract_fee_id) {
                preMoney += item.balance * 1;
                id = item.contract_fee_id;
              }
              totalMoney += item.payment_money * 1;
            })
            this.detailDataRow = res.data;
            this.detailDataRowObj.preMoney = Number(preMoney.toFixed(2));
            this.detailDataRowObj.totalMoney = Number(totalMoney.toFixed(2));
          }
          this.vouchervasible = true;
        });
      } else {
        let rowData = [];
        if (row.payment_info) {
          rowData = JSON.parse(row.payment_info);
        }
        let totalMoney = 0;
        rowData.forEach((item) => {
          totalMoney += item.payment_money * 1;
        });
        this.detailDataRowObj.totalMoney = Number(totalMoney.toFixed(2));

        // much_balance_price
        if (row.advance_info) {
          let data = JSON.parse(row.advance_info);
          let preMoney = 0;
          Array.isArray(data) &&
            data.forEach((item) => {
              preMoney += item.much_balance_price * 1;
            });
          this.detailDataRowObj.preMoney = Number(preMoney.toFixed(2));
        }
        this.detailDataRow = rowData;
        this.vouchervasible = true;
      }

    },
    getStatistics () {
      this.$request
        .HttpGet("/bill_offline/offlineOverview", this.params)
        .then((res) => {
          this.statistics = res.data;
        });
    },
    changeDate (date) {
      if (date) {
        this.params.pay_time_start = date[0];
        this.params.pay_time_end = date[1];
      } else {
        this.params.pay_time_start = "";
        this.params.pay_time_end = "";
      }
    },
    currentChange (page) {
      this.params.page = page;
      this.getList();
    },
    getList () {
      this.loading = true;
      this.$request
        .HttpPost("/bill_offline/offlineList", this.params)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list || [];
          this.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    checkdgong (row) {
      if (!row.gong_voucher)
        return this.$message({ type: "warning", message: "暂无凭证" });
      this.gong_voucher = this.getImg(row.gong_voucher);
      if (!this.gong_voucher || this.gong_voucher.length === 0)
        return this.$message({ type: "warning", message: "暂无凭证" });
      this.dgvisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-imgx-box {
  position: relative;
  margin-right: 15px;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  display: inline-block;

  .upload-img {
    width: 100%;
    height: 100%;
  }

  span {
    position: absolute;
    right: -10px;
    top: -10px;
    display: block;
    width: 15px;
    height: 15px;
    border: 1px solid rgb(110, 108, 108);
    border-radius: 50%;
    text-align: center;
    line-height: 12px;
    cursor: pointer;
  }
}

.chargeoofline-box {
  height: calc(100vh - 205px);
}

.chargeoofline-table {
  height: 80%;
}

.charageoff-page {
  text-align: right;
  margin-top: 40px;
}

.top-box {
  height: 98px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .item {
    width: 316px;
    height: 60px;
    border-right: 1px solid #f5f5f5;
    padding-left: 98px;
    box-sizing: border-box;

    &:last-child {
      border-right: none;
    }

    img {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }

    span {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }

    div {
      &:nth-child(1) {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }

      &:nth-child(2) {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-top: 5px;
      }
    }
  }
}

.label-width {
  width: 220px;
}

.itembox {
  border: 1px solid #f5f5f5;
  margin-bottom: 15px;

  .title {
    padding: 15px;
    box-sizing: border-box;
  }
}

.rent {
  .contract {
    border-bottom: 1px solid #f0ecec;
  }

  .contract:nth-last-of-type(2) {
    border-bottom: none;
  }
}

.dgimg {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
</style>
