<template>
  <div class="voucher">
    <div class="dash">
      <div class="title">凭证名称</div>
     <div class="info">
           <div>
               <span>姓名:</span>
               <span><姓名></span>
           </div>
           <div>
             <span>面积:</span>
             <span><面积></span>
           </div>
           <div>
             <span>房号:</span>
             <span><房号></span>
           </div>
           <div>
             <span>付款方式:</span>
             <span><付款方式></span>
           </div>
           <div>
             <span>收款时间:</span>
             <span><收款时间></span>
           </div>
            <div>
             <span>订单号:</span>
             <span><订单号></span>
           </div>
       </div>
       <!-- table -->
       <div class="table">
         <table border>
           <thead>
             <td>收费项目</td>
             <td>起始日期</td>
             <td>截止日期</td>
             <td>上期读数</td>
             <td>本期读数</td>
             <td>用量</td>
             <td>单价</td>
             <td>应收金额</td>
             <td>减免金额</td>
             <td>滞纳金</td>
             <td>备注</td>
           </thead>
           <tr>
              <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
           </tr>
               <tr>
              <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
           </tr>
         </table>
       </div>
       <!-- je -->
       <div class="moneny">
         <div>
           <span>实收金额:</span>
           <span class="line"></span>
         </div>
         <div>
           <span>实收金额人民币(大写):</span>
           <span class="line"></span>
         </div>
         <div>
           <span>收款人:</span>
           <span class="line"></span>
         </div>
       </div>
       <!-- tips -->
       <div class="tips">
         <span>说明:</span>
         <span v-html="data.notes"></span>
        
       </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "voucher",
  props: {
    data: {
      type: Object,
      default: () => {
        return {
         notes:`
         <p>1、业主（住户）应于每月15日前到物管处缴纳上月物业管理费等相关费用</p>

<p>2、管理处将在次月15日后按天收取滞纳金，比率：水费（0.001）、管理费（0.003）</p>

<p>3、业主（住户）对费用如有疑问，请致电管理处电话：**************咨询。多谢合作！</p>
         `
          // yzImage:''
        };
      },
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.voucher {
 width: 1192px;
height: 462px;
background: #FAFAFA;
border: 1px solid #DEDEDE;
// box-sizing: border-box;
display: flex;
align-items: center;
justify-content: center;
.msg{
  color:#807d7d;
}
  .dash {
    width: 1184px;
    height: 454px;
    background: #ffffff;
    border: 1px dashed #dedede;
    padding: 36px 136px 29px 136px;
    box-sizing: border-box;
    position: relative;
  }
  .title {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    width: 100%;
    text-align: center;
  }
  .info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;
}
 
 .table{
   table{
     width: 100%;
     border: 1px solid rgb(196, 191, 191);
     td{
       height: 20px;
       text-align: center;
     }
   }
 }
 .moneny{
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin: 20px 0;
   span{
     display: inline-block;
     height: 50px;
     line-height: 50px;
   }
   .line{
     width: 100px;
    border-bottom: 1px solid #858282;
    margin-left: 6px;
   }
 }
.tips{
    font-size: 12px;
    margin-top: 25px;
    p{
      text-indent: 35px;
    }
  }
}
</style>
